import React , { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Testimonail from "../components/testimonial";

//AOS
import AOS from "aos";
import "aos/dist/aos.css";

export default function MarketPlace() {

  useEffect(() => {

    AOS.init({

    });
    
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Marketplace (B2B) | OIC - Online platform to buy and sell optical
          products
        </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="Marketplace (B2B) | OIC - Online platform to buy and sell optical products"
        />
        <meta
          name="description"
          content="OIC platform increases B2B field revenue and provides their customers with a B2B eCommerce storefront for self-service ordering. Our library has around 15000 SKUs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="OIC platform increases B2B field revenue and provides their customers with a B2B eCommerce storefront for self-service ordering. Our library has around 15000 SKUs."
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/marketplace-banner.jpg")`,
          }}
        >
          <img src="" alt="Variety of branded glasses" className="altImage" />
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  <span>ONLINE PLATFORM</span> TO BUY AND SELL{" "}
                  <span>OPTICAL PRODUCTS.</span>
                </h1>
                <a href="https://web-dev-release.oicapps.com/" target="_blank" className="buttonStyleWhite hvr-forward">
                  Start Free Demo
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <div className="container">
            <div className="row aligner" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-2"></div>
              <div className="col-md-8 text-center">
                <h2>
                  <span className="realistic-marker-highlight">
                    Marketplace
                  </span>
                </h2>

                <p style={{ textAlign: "center", lineHeight: "2" }}>
                  The platform embarks on and uploads its stock data to the
                  inventory of the brands or distributors. Luxury and
                  cost-effective goods under a roof. Our marketplace has 10163+
                  listed products. The retailers buy items specified by the
                  distributor. Furthermore, the dealer can also purchase
                  products from different distributors. Over 15000 SKUs are in
                  our library and vendors and outlets can place orders with the
                  distributor and effortlessly add the product.
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>

        <div className="padding60">
          <div className="container" data-aos="fade-right" data-aos-duration="400">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2>
                  <span className="realistic-marker-highlight">Features</span>
                </h2>
              </div>
              <div className="container IconBoxText">
                <div className="row featureglass">
                  <div className="featureglassesM"><img src={require("../img/hp/Features_Glass.png")} /></div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsL" />
                    <div className="transitionRingsModifiedL" />
                    <div className="featureMarginCorrection IconBox blinkTransition" style={{marginTop:"2%"}}>
                      <img className="transitionMarketImages"></img>
                      <p className="transitionMarketText">
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc featureMarginTextCorrection">
                      Once a buyer decides on a purchase, the goal of any
                      marketplace site is to make the process of check-out as
                      efficient, intuitive, and painless as possible.
                    </p>
                  </div>
                  <div className="col-md-6 text-center">
                    <div className="transitionRingsR" />
                    <div className="transitionRingsModifiedR" />
                    <div className="IconBox blinkTransition" style={{marginTop:"2%"}}>
                      <img className="transitionMarketImages transitionDirection"></img>
                      <p className="transitionMarketText transitionDirection">
                      </p>
                    </div>
                    <br></br>
                    <p className="featureIconDesc">
                      A dashboard gives you an at-a-glance view of how your
                      business is doing. It’s a great feature to have because it
                      gives you an immediate of your business’ performance
                      without having to dig or hunt for the info.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="blueBackground">
          <section className="container-fluid " data-aos="fade-left" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4 padding60">
                <h2 className="realistic-marker-highlight">
                  Inventory Library
                </h2>
                <p>
                  OiC has 1066 Unique Products and 84881 Total Inventories are
                  available. Inventory Library, assists with keeping away from
                  gigantic bugs spent on inventories. We have produced 15,000+
                  inventories of famous brands in the database for the
                  distributors' easy use and inventories are available in
                  metadata. OIC allows distributors to add inventory from their
                  inventory library or upload their own inventory.
                </p>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>
              <div className="col-md-6 noPadding DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/market-place-02.png"
                  className="img-responsive" alt="OIC Inventory glasses"
                ></img>
              </div>
            </div>
          </section>
        </div>

        <div className="padding60">
          <section className="container-fluid " data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/market-place-03.png"
                  className="img-responsive" alt="Order purchasing through the OIC platform"
                ></img>
              </div>
              <div className="col-md-4 padding60">
                <h2 className="realistic-marker-highlight">Purchasing</h2>
                <p>
                  My Inventories will be displayed on the marketplace for
                  retailers to purchase. Use choices like Shop By Brands and
                  Shop By Categories to make retailer shopping easier.
                </p>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>

              <div className="col-md-2"></div>
            </div>
          </section>
        </div>

        <div className="padding60 blueBackground">
          <section className="container-fluid " data-aos="fade-left" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">Shop By Brands</h2>
                <p>
                  Selecting a particular brand and listing the items will make
                  it easier. Our Top Brands are Ray-Ban, DKNY, Fastrack,
                  VELOCITY etc..
                </p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>
              <div className="col-md-4 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/brands.svg"
                  className="img-responsive" alt="Branded Glasses"
                ></img>
              </div>
              <div className="col-md-2"></div>
            </div>
          </section>
        </div>

        <div className="padding60" style={{ background: "#ECEDEF" }}>
          <section className="container-fluid" data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4 noPadding DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/market-place-06.png"
                  className="img-responsive" alt="Eyewear glasses"
                ></img>
              </div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">
                  Shop By Categories
                </h2>
                <p>
                  Filter depending on the type of glasses you like, such as
                  eyewear, sunglasses, computer glasses, reading glasses, and
                  contact lenses.
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-4 noPadding mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/market-place-06.png"
                  className="img-responsive" alt="Eyewear glasses"
                ></img>
              </div>
            </div>
          </section>
        </div>

        <div className="padding60">
          <section className="container-fluid " data-aos="fade-left" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <h2 className="realistic-marker-highlight">My Order</h2>
                <p>Provides Cart items, approved orders and history.</p>
                <a href="#" className="buttonStyle hvr-forward">
                  Try Now
                </a>
                <br></br> <br></br>
                <br></br> <br></br>
              </div>
              <div className="col-md-6 noPadding">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/market-place-04.jpg"
                  className="img-responsive" alt="Order successfully placed"
                ></img>
              </div>
            </div>
          </section>
        </div>

        <div className="padding60 blueBackground">
          <section className="container-fluid" data-aos="fade-right" data-aos-duration="400">
            <div className="row aligner">
              <div className="col-md-7 noPadding DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/market-place-05.jpg"
                  className="img-responsive" alt="retailer checking incoming order in OIC platform"
                ></img>
              </div>
              <div className="col-md-4 paddingLeft">
                <h2 className="realistic-marker-highlight">Incoming Orders</h2>
                <p>
                  New orders for distributors would be classified as incoming
                  orders with accepted and order history.
                </p>
                <br></br> <br></br>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-7 noPadding mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/market-place-05.jpg"
                  className="img-responsive" alt="retailer checking incoming order in OIC platform"
                ></img>
              </div>
            </div>
          </section>
        </div>

        <Testimonail />
      </Layout>
    </div>
  );
}
